/**
 * @param {string} hexColor - #982b3c - expects full six character hex color
 * @param {number} [brightnessThreshold] floating point threshold, max value = 1, min value = 0
 * @returns {boolean}
 */

export default function isHexColorBright(
  hexColor: string,
  brightnessThreshold = 0.5,
) {
  const colorUnits = +("0x" + hexColor.slice(1));

  const r = colorUnits >> 16;
  const g = (colorUnits >> 8) & 255;
  const b = colorUnits & 255;

  // Based on https://alienryderflex.com/hsp.html
  const brightness =
    // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
    Math.sqrt((0.299 * (r * r)) + (0.587 * (g * g)) + (0.114 * (b * b))) / 255;

  return brightness > brightnessThreshold;
}
