import consumerApi from "api/services/consumer-api-fetch";
import { AdMarqueeType, Creative } from "custom-types/AdMarquee";
import { Coordinates } from "custom-types/Coordinates";
import logError from "utils/logError";

export const fallBackHouseAd = {
  backgroundColor: "#DEF3FF",
  backgroundImageDesktop: null,
  backgroundImageMobile: null,
  brandId: null,
  buttonColor: "#017C6B",
  buttonText: "get the app",
  buttonTextColor: "#FFFFFF",
  creativeType: AdMarqueeType.HOUSE,
  destinationUrl:
    "https://onelink.to/leaflymarketing?itm_source=blast&itm_medium=marquee&itm_campaign=trf-all-app-marquee-usa-all",
  dispensaryId: null,
  headline: "Download your portable budtender",
  imageUrl:
    "https://directus-media.leafly.com/85bade3a-41fa-431a-a1e2-398c7b8ccd3b.png?auto=compress,format&w=100&dpr=2",
  merchandisingCampaignId: null,
  textColor: "#333333",
  topLine: "Leafly",
};

const getMarqueeCreatives = async (
  pageType: string,
  coordinates: Coordinates,
): Promise<Creative[]> => {
  const { data, response } = await consumerApi.GET(
    "/api/marquee_creatives/v1",
    {
      params: {
        query: {
          lat: String(coordinates.lat),
          lon: String(coordinates.lon),
          page_type: pageType,
        },
      },
    },
  );

  if (response.ok && data) {
    if (data.marqueeCreatives.length < 1) {
      return [fallBackHouseAd];
    } else {
      return data.marqueeCreatives.map((c) => ({
        ...c,
        creativeType: c.creativeType as AdMarqueeType,
      }));
    }
  } else {
    logError("Failed to find marquee creatives", {
      functionName: "getMarqueeCreatives",
      service: "consumer",
      statusCode: response.status,
    });

    return [fallBackHouseAd];
  }
};

export default getMarqueeCreatives;
